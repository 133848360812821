import * as React from 'react';

import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { FaApple, FaCheck, FaGooglePlay, FaMapMarkerAlt } from 'react-icons/fa';

import { Colors } from '../../assets/theme';
import Footer from '../../components/website/footer/Footer';
import WebsiteHeader from '../../components/website/header/Header';

const WebsiteHomePage = () => {
  const constraintsRef = React.useRef(null);
  return (
    <Box>
      <WebsiteHeader />
      <Box
        sx={{
          backgroundPosition: '50%',
          backgroundImage: 'url(\'/images/location_background.png\')',
          height: '450px',
          position: 'relative',
          zIndex: -5,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(5, 75, 141, 0.40)',
            zIndex: -1,
          }}
        />
        <Container sx={{ px: {md: 5, mdd: 8, lg: 12}, zIndex: 8, height: '100%' }} maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              height: '100%',
              zIndex: 8,
              color: 'white',
            }}
          >
            <Box zIndex={8}>
              <Typography variant="h3" fontWeight={'bold'} mb={3}>
                Addressing Made Easy
              </Typography>
              <Typography variant="h4">
                Best addressing system, yet simple
              </Typography>
              <Stack alignItems={'center'} direction="row" spacing={2} mt={3}>
                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {' '}
                  Request a Demo
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    bgcolor: 'white',
                    textTransform: 'capitalize',
                    borderColor: 'white',
                  }}
                >
                  {' '}
                  Sign up
                </Button>
              </Stack>
            </Box>
            <Box sx={{ color: 'white', px: 4, flexBasis: '2',  display: {xs: 'none', smm: 'none', md: 'block'} }}>
              <motion.div
                className="anime"
                ref={constraintsRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                }}
              >
                <motion.h1
                  animate={{ scale: [0, 1, 1, 1, 0, 0, 0] }}
                  initial={{ scale: 0 }}
                  transition={{ delay: 1, duration: 8, repeat: Infinity }}
                  onAnimationEnd={() => console.log('first')}
                  style={{
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 10,
                    textAlign: 'center',
                    paddingRight: 50,
                    paddingLeft: 50,
                  }}
                >
                  Geo-location <br />
                  long, lat <br />
                  (5.6789, 1.6778) <br />
                  transformed to
                </motion.h1>

                <motion.h1
                  animate={{ scale: [1, 0, 0, 0, 1, 1, 1] }}
                  initial={{ scale: 1 }}
                  transition={{ delay: 1, duration: 8, repeat: Infinity }}
                  onAnimationEnd={() => console.log('first')}
                  style={{
                    fontWeight: 'bold',
                    marginTop: 0,
                    marginBottom: 10,
                    textAlign: 'center',
                    paddingRight: 50,
                    paddingLeft: 50,
                    position: 'absolute',
                  }}
                >
                  Pluscode <br />
                  (C017A-GT) <br />
                  transformed to
                </motion.h1>

                <motion.div
                  animate={{
                    x: [
                      0, -400, -400, -400, -400, 0, 0, 0, 0, 400, 400, 400, 400,
                      0, 0, 0, 0,
                    ],
                  }}
                  initial={{ x: 0 }}
                  transition={{ delay: 2, duration: 8, repeat: Infinity }}
                  onAnimationEnd={() => console.log('first')}
                  style={{
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    width: '230px',
                    height: '280px',
                    backgroundColor: Colors.primary,
                    display: 'flex',
                  }}
                >
                  <img
                    src="/images/logo_plus_location_icon.svg"
                    alt="logo_plus_location_icon.svg"
                  />
                </motion.div>
              </motion.div>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box mt={12}>
        <Container sx={{ px: {md: 5, mdd: 8, lg: 12} }} maxWidth="xl">
          <Stack alignItems={'center'} direction={{xs: 'column', md: 'row'}} spacing={2}>
            <Box sx={{ width: {xs: '90%', md:'50%'} }}>
              <Typography
                variant="h4"
                mb={2}
                color="primary"
                fontWeight={'500'}
              >
                Addressing the problem of poor addressing
              </Typography>
              <span
                style={{
                  width: 100,
                  height: '4px',
                  backgroundColor: Colors.primary,
                  borderRadius: '20px',
                  display: 'block',
                  marginBottom: '6px',
                }}
              />
              <Typography mb={2} mt={5} lineHeight={1.8}>
                Around the world, 4 billion are unaddressed. In Africa alone,
                there are 440 million people without a recorded place to call
                home.
              </Typography>
              <Stack alignItems={'center'} direction="row" spacing={2} mt={5}>
                <Button variant="outlined" startIcon={<FaGooglePlay />}>
                  Google Play
                </Button>
                <Button variant="contained" startIcon={<FaApple />}>
                App Store
                </Button>
              </Stack>
            </Box>

            <Box sx={{ width: {xs: '90%', md:'50%'}, mt: {xs: '70px !important', md: 0} }}>
              <Stack
                direction={{xs: 'column', sm: 'row'}}
                alignItems={'center'}
                justifyContent="space-around"
              >
                <Box>
                  <Card
                    sx={{
                      padding: 3,
                      color: Colors.primary,
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <FaMapMarkerAlt size={'3.5em'} />
                    <Typography variant="h4" mt={2}>
                      Addressing
                    </Typography>
                  </Card>
                </Box>

                <Box>
                  <Card
                    sx={{
                      padding: 3,
                      color: Colors.primary,
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mt: {xs: '30px !important', sm: 0}
                    }}
                  >
                    <FaMapMarkerAlt size={'3.5em'} />
                    <Typography variant="h4" mt={2}>
                      Addressing
                    </Typography>
                  </Card>
                  <Card
                    sx={{
                      padding: 3,
                      color: Colors.primary,
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mt: 4,
                    }}
                  >
                    <FaMapMarkerAlt size={'3.5em'} />
                    <Typography variant="h4" mt={2}>
                      Addressing
                    </Typography>
                  </Card>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Box mt={15}>
        <Container sx={{ px: {md: 5, mdd: 8, lg: 12} }} maxWidth="xl">
          <Stack alignItems={'center'} direction="row" spacing={2}>
            <Box sx={{ width: '50%', display: {xs: 'none', md: 'block'}}}>
              <CardMedia
                component="img"
                image="/images/undraw_directions_re_kjxs 1.png"
                alt="Doormot Logo"
                sx={{
                  mr: 2,
                  display: { md: 'flex' },
                  pl: { xs: 4 },
                  width: 450,
                }}
              />
            </Box>
            <Box
              sx={{width: {xs: '90%', md:'50%'}, textAlign: {xs: '-webkit-left', md: '-webkit-right'}  }}
              textAlign={{xs: 'left', md: 'right'}}
            >
              <Typography
                variant="h4"
                mb={2}
                color="primary"
                fontWeight={'500'}
                textAlign={{xs: 'left', md: 'right'}}
              >
                Reliable addressing system for the unaddressed
              </Typography>
              <Box
                sx={{
                  width: 100,
                  height: '4px',
                  backgroundColor: Colors.primary,
                  borderRadius: '20px',
                  display: 'block',
                  marginBottom: '6px',
                  alignSelf: {xs: 'flex-start', md: 'flex-end'},
                }}
              />
              <Typography mb={2} mt={5} lineHeight={1.8}  textAlign={{xs: 'left', md: 'right'}}>
                We are trying to solve the fundamental problem of addressing
                which is missing in Africa and many developing countries. In
                solving this core problem we also unlocks the potential in
                various industries to connect people to a range of convenience
                goods and services;
              </Typography>
              <Stack
                alignItems={'center'}
                direction="row"
                spacing={2}
                mt={5}
                justifyContent={{xs: 'flex-start', md: 'flex-end'}}
              >
                <Box>
                  <Typography display={'flex'} alignItems="center" mt={1}>
                    <FaCheck style={{ marginRight: '8px' }} /> Logistics/Doormot
                    delivery
                  </Typography>
                  <Typography display={'flex'} alignItems="center" mt={1}>
                    <FaCheck style={{ marginRight: '8px' }} /> Transportation
                    /doormot transport
                  </Typography>
                  <Typography display={'flex'} alignItems="center" mt={1}>
                    <FaCheck style={{ marginRight: '8px' }} />{' '}
                    Restaurant/Doormot food
                  </Typography>
                </Box>
                <Box>
                  <Typography display={'flex'} alignItems="center" mt={1}>
                    <FaCheck style={{ marginRight: '8px' }} /> E commerce
                    /Doormot shopping
                  </Typography>
                  <Typography display={'flex'} alignItems="center" mt={1}>
                    <FaCheck style={{ marginRight: '8px' }} /> Hospital /Doormot
                    health
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* <Box mt={15} bgcolor={Colors.primary}>
        <Container sx={{ px: {md: 5, mdd: 8, lg: 12} }} maxWidth="xl">
          <Stack
            alignItems={'center'}
            direction="row"
            justifyContent={'space-around'}
            p={5}
          >
            <Box textAlign="center">
              <Typography variant="h3" color="white" fontWeight={'500'}>
                2,533
              </Typography>
              <Typography color="white" variant="h4">
                Users
              </Typography>
            </Box>

            <Box textAlign="center">
              <Typography variant="h3" color="white" fontWeight={'500'}>
                7,306
              </Typography>
              <Typography color="white" variant="h4">
                Address
              </Typography>
            </Box>

            <Box textAlign="center">
              <Typography variant="h3" color="white" fontWeight={'500'}>
                286
              </Typography>
              <Typography color="white" variant="h4">
                Businesses
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box> */}

      <Box mt={10}>
        <Container sx={{ px: {md: 5, mdd: 8, lg: 12} }} maxWidth="xl">
          <Stack
            alignItems={'center'}
            direction={{xs: 'column', sm: 'row'}}
            justifyContent={'space-around'}
            p={4}
          >
            <Typography variant="h5" fontWeight={'500'} width={{xs: '90%', sm:'50%'}}>
              Focus on growing your business, Let Doormot do the delivery
            </Typography>
            <Button
              variant="contained"
              sx={{
                textTransform: 'capitalize',
                mt: {xs: 4, sm: 0}
              }}
            >
              Request a Demo
            </Button>
          </Stack>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default WebsiteHomePage;
