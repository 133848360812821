import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';


const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#0082FF',
      dark: '#0082FF',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smm: 650,
      md: 900,
      mdd: 1100,
      lg: 1200,
      xl: 1536,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
