import React from 'react';

import { Box, Button, CardMedia, Container, Link, Stack, Typography } from '@mui/material';
import { FaFacebook, FaLinkedin, FaTwitterSquare } from 'react-icons/fa';

import { Colors } from '../../../assets/theme';

const Footer = () => {
  return (
    <Box mt={10} bgcolor={Colors.primary} color="white">
      <Container sx={{px: 0}} maxWidth="xl">
        <Stack
          alignItems={'center'}
          direction="row"
          justifyContent={'space-between'}
          p={{xs: 1, sm: 4}}
        >
          <Box>
            <CardMedia
              component="img"
              image="/images/logo.svg"
              alt="Doormot Logo"
              sx={{
                mr: 2,
                display: { md: 'flex' },
                pl: { xs: 4 },
                width: 150,
              }}
            />
            <Typography
              variant="h5"
              fontWeight={'500'}
              sx={{
                mr: 2,
                pl: { xs: 4 },
                pt: { xs: 2 },
                mb: { xs: 2 },
                mt: 2,
              }}
            >
            Start addressing now!
            </Typography>

            <Button
              variant="outlined"
              sx={{
                bgcolor: 'white',
                textTransform: 'capitalize',
                borderColor: 'white',
                mr: 2,
                ml: { xs: 4 },
                mt: 2,
              }}
            >
            Request a Demo
            </Button>
          </Box>
          <Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Link href="#" color="inherit" mt={1}>Home</Link>
              <Link href="#" color="inherit" mt={1}>
Business
              </Link>
              <Link href="#" color="inherit" mt={1}>
Personal
              </Link>
              <Link href="#" color="inherit" mt={1}>
Developer
              </Link>
            </Box>
            <Typography
              fontWeight={'500'}
              sx={{
                pt: { xs: 2 },
                mb: { xs: 2 },
                mt: 2,
              }}
            >
         Copyright @ Doormot 2022 <br />
          All rights reserved.
            </Typography>
            <Box mt={3}>
              <Link  color="inherit" mr={2} href="/">
                <FaFacebook size={'2.3em'} />
              </Link>
              <Link  color="inherit" mr={2} href="/">
                <FaLinkedin size={'2.3em'} />
              </Link>
              <Link  color="inherit" mr={2} href="/">
                <FaTwitterSquare size={'2.3em'} />
              </Link>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
