import React from 'react';

import { Routes, Route, } from 'react-router-dom';

import './App.css';
import WebsiteHomePage from './website/home';

function App() {
  return (
    <Routes>
      <Route path="/" element={<WebsiteHomePage />} />
    </Routes>
  );
}

export default App;
